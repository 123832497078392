import React from 'react';
import './App.css';
import { useListEvent } from './hooks/eventCustomHook';
import LoadingScreen from './containers/LoadingScreen';
import EventScreen from './containers/EventScreen';

function App() {
  const { eventList, loading } = useListEvent()

  if (loading) {
    return (<LoadingScreen />)
  }

  return (<EventScreen events={eventList} />);
}

export default App;
