import { useCallback, useEffect, useState } from "react"
import { db } from "../firebase"
import { onValue, ref } from "firebase/database"
import { EventPlayer, EventTable, EventTheme } from "../domain/events/model"

export const useEventTable = (id: string) => {
    const [event, setEvent] = useState<EventTable>()
    const [loading, setLoading] = useState(true)
    const [eventId, setEventId] = useState(id)

    const Reload = useCallback((id: string) => {
        setEventId(id)
    }, [])

    useEffect(() => {
        const eventRef = ref(db, `events/${eventId}`)
        return onValue(eventRef, (snapshot) => {
            const data = snapshot.val()

            const players: EventPlayer[] = []
            if (data.players) {
                for (const k in data.players) {
                    players.push(new EventPlayer(data.players[k]))
                }
            }

            const theme = new EventTheme({
                logo: data?.theme?.logo || data?.logo,
                background: data?.theme?.background || data?.background,
                mainColor: data?.theme?.mainColor || data?.color,
                checkedColor: data?.theme?.checkedColor,
                checkInColor: data?.theme?.checkInColor,
                groupColor: data?.theme?.groupColor,
                holeColor: data?.theme?.holeColor,
                parColor: data?.theme?.parColor,
                pendingColor: data?.theme?.pendingColor,
                groupBackground: data?.theme?.groupBackground,
                holeBackground: data?.theme?.holeBackground,
                borderColor: data?.theme?.borderColor
            })

            const event = new EventTable({
                id: eventId,
                name: data.name,
                type: data.type,
                enable: data.enable,
                players: players,
                hole: data.hole,
                theme: theme,
            })

            setEvent(event)

            setLoading(false)
        })
    }, [eventId])

    return {
        event, loading, Get: Reload
    }
}

export const useListEvent = () => {
    const [eventList, setEventList] = useState<EventTable[]>([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const eventRef = ref(db, `events`)
        return onValue(eventRef, (snapshot) => {
            const data = snapshot.val()

            const list: EventTable[] = []
            for (const key in data) {

                const theme = new EventTheme({
                    logo: data[key]?.theme?.logo || data[key]?.logo,
                    background: data[key]?.theme?.background || data[key]?.background,
                    mainColor: data[key]?.theme?.mainColor || data[key]?.color,
                    checkedColor: data[key]?.theme?.checkedColor,
                    checkInColor: data[key]?.theme?.checkInColor,
                    groupColor: data[key]?.theme?.groupColor,
                    holeColor: data[key]?.theme?.holeColor,
                    parColor: data[key]?.theme?.parColor,
                    pendingColor: data[key]?.theme?.pendingColor,
                    groupBackground: data[key]?.theme?.groupBackground,
                    holeBackground: data[key]?.theme?.holeBackground,
                    borderColor: data[key]?.theme?.borderColor
                })

                const event = new EventTable({
                    id: key,
                    name: data[key].name,
                    type: data[key].type,
                    enable: data[key].enable,
                    players: [],
                    hole: data[key].hole,
                    theme: theme,
                })

                list.push(event)
            }

            setEventList(list.sort((a: EventTable, b: EventTable) => a.id < b.id ? -1 : 1))
            setLoading(false)
        })
    }, [])

    return { eventList, loading }
}