import React, { useEffect, useState } from 'react'
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons'
import { Button, Flex, Image, Layout, List, Modal, Typography } from 'antd'
import { useEventTable } from '../hooks/eventCustomHook'
import { EventPlayer, EventTable, EventTheme } from '../domain/events/model'
import LoadingScreen from './LoadingScreen'
import { isMobile } from 'react-device-detect'

const tableTemplate = [
    {
        type: 1,
        group: 2,
        member: 4,
        hole: [
            {
                hole: 1,
                par: 4,
            },
            {
                hole: 2,
                par: 5,
            },
            {
                hole: 3,
                par: 3,
            },
            {
                hole: 4,
                par: 4,
            },
            {
                hole: 5,
                par: 4,
            },
            {
                hole: 6,
                par: 3,
            },
            {
                hole: 7,
                par: 4,
            },
            {
                hole: 8,
                par: 4,
            },
            {
                hole: 9,
                par: 5,
            },
            {
                hole: 10,
                par: 4,
            },
            {
                hole: 11,
                par: 5,
            },
            {
                hole: 12,
                par: 3,
            },
            {
                hole: 13,
                par: 4,
            },
            {
                hole: 14,
                par: 4,
            },
            {
                hole: 15,
                par: 4,
            },
            {
                hole: 16,
                par: 3,
            },
            {
                hole: 17,
                par: 4,
            },
            {
                hole: 18,
                par: 5,
            },
        ]
    },
    {
        type: 2,
        group: 3,
        member: 4,
        hole: [
            {
                hole: 1,
                par: 5,
            },
            {
                hole: 2,
                par: 4,
            },
            {
                hole: 3,
                par: 4,
            },
            {
                hole: 4,
                par: 4,
            },
            {
                hole: 5,
                par: 3,
            },
            {
                hole: 6,
                par: 5,
            },
            {
                hole: 7,
                par: 4,
            },
            {
                hole: 8,
                par: 3,
            },
            {
                hole: 9,
                par: 4,
            },
            {
                hole: 10,
                par: 4,
            },
            {
                hole: 11,
                par: 3,
            },
            {
                hole: 12,
                par: 4,
            },
            {
                hole: 13,
                par: 4,
            },
            {
                hole: 14,
                par: 4,
            },
            {
                hole: 15,
                par: 3,
            },
            {
                hole: 16,
                par: 5,
            },
            {
                hole: 17,
                par: 4,
            },
            {
                hole: 18,
                par: 5,
            },
        ]
    },
    {
        type: 3,
        group: 3,
        member: 5,
        hole: [
            {
                hole: 1,
                par: 5,
            },
            {
                hole: 2,
                par: 4,
            },
            {
                hole: 3,
                par: 4,
            },
            {
                hole: 4,
                par: 4,
            },
            {
                hole: 5,
                par: 3,
            },
            {
                hole: 6,
                par: 5,
            },
            {
                hole: 7,
                par: 4,
            },
            {
                hole: 8,
                par: 3,
            },
            {
                hole: 9,
                par: 4,
            },
            {
                hole: 10,
                par: 4,
            },
            {
                hole: 11,
                par: 3,
            },
            {
                hole: 12,
                par: 4,
            },
            {
                hole: 13,
                par: 4,
            },
            {
                hole: 14,
                par: 4,
            },
            {
                hole: 15,
                par: 3,
            },
            {
                hole: 16,
                par: 5,
            },
            {
                hole: 17,
                par: 4,
            },
            {
                hole: 18,
                par: 5,
            },
        ]
    }
]

function HoleTable(props: { theme?: EventTheme, hole: number, par: number, group: number, memberTotal: number, parList: { hole: number, par: number }[], players: EventPlayer[] }) {
    return (
        <Flex style={{ alignItems: 'center' }} vertical={false}>
            <Flex vertical={true} style={{
                width: '40%',
                maxWidth: '5rem',
                height: '100%',
                backgroundColor: props.theme?.holeBackground,
            }}>
                <Typography.Text style={{
                    color: props.theme?.holeColor,
                    textTransform: 'uppercase',
                    fontFamily: 'Bebas Neue',
                    fontSize: '1.5rem',
                    letterSpacing: '0.1rem'
                }}>Hole {props.hole}</Typography.Text>
                <Typography.Text style={{
                    color: props.theme?.parColor,
                    fontFamily: 'Lexend',
                    fontSize: '1rem'
                }}>Par {props.par}</Typography.Text>
            </Flex>
            <Flex vertical={isMobile} style={{ width: '100%', height: '100%' }}>
                {new Array(props.group).fill(0).map((e: number, index: number) => {
                    const players = props.players.filter((e: EventPlayer) => e.group === index + 1).sort((a: EventPlayer, b: EventPlayer) => a.getSortIndex().localeCompare(b.getSortIndex())).map((e: EventPlayer, index: number) => {
                        e.order = index
                        return e
                    })
                    const groupPlayer = new Array(props.memberTotal).fill(new EventPlayer()).map((v, i) => {
                        if (i === 0) {
                            const captain = players.find((e: EventPlayer) => e.isCaptain)
                            if (captain) {
                                return captain
                            }
                        }

                        const player = players.find((e: EventPlayer) => e.order === i)
                        if (player) {
                            return player
                        }

                        return v
                    })

                    return (
                        <Flex key={index} vertical={true} style={{
                            width: '100%',
                            borderLeftColor: props.theme?.borderColor,
                            borderLeftWidth: '1px',
                            borderLeftStyle: 'solid',
                            marginRight: '0.5rem', marginBottom: '1rem'
                        }}>
                            <Flex style={{ backgroundColor: props.theme?.groupBackground, filter: 'brightness(1.5)', paddingLeft: '1rem' }}>
                                <Typography.Text style={{
                                    fontFamily: 'Lexend',
                                    padding: '0.15rem',
                                    fontWeight: 'bold',
                                    color: props.theme?.groupColor,
                                }}>Group {index + 1}</Typography.Text>
                            </Flex>
                            {groupPlayer.map((e: EventPlayer, index: number) => {
                                return (
                                    <Flex key={index} style={{
                                        paddingLeft: '1rem',
                                        paddingTop: '0.25rem',
                                        backgroundColor: props.theme?.mainColor,
                                    }}>
                                        <Typography.Text style={{
                                            color: e.checked ? props.theme?.checkedColor : e.isCheckIn() ? props.theme?.checkInColor : props.theme?.pendingColor,
                                            height: '1.38rem',
                                            fontFamily: 'Noto Sans Thai',
                                            fontWeight: e.isCaptain ? 'bold' : 'normal'
                                        }}>{e.firstname} {e.lastname}</Typography.Text>
                                    </Flex>
                                )
                            })}
                        </Flex>
                    )
                })}
            </Flex>
        </Flex>
    )
}

function PageButton(props: React.PropsWithChildren<{ isMobile: boolean, onClick?: () => void, disabled?: boolean, backgroundColor?: string }>) {
    return (
        <Button
            style={{
                backgroundColor: props.backgroundColor || 'white',
                borderRadius: '0',
                height: props.isMobile ? '3rem' : '40vh',
                filter: 'brightness(1.5)',
                borderLeft: 'none',
                borderTop: 'none',
                borderColor: `${props.backgroundColor}`,
                width: props.isMobile ? '6rem' : '3rem',
                boxShadow: '2px 2px 2px'
            }}
            disabled={props.disabled}
            onClick={() => { props.onClick && props.onClick() }}
        >
            {props.children}
        </Button>
    )
}

function EventScreen(props: { events: EventTable[] }) {
    const { event, loading, Get } = useEventTable(props.events[0].id)
    const [isFirstPage, setIsFirstPage] = useState(true)
    const [dataTable, setDataTable] = useState<EventPlayer[]>()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [eventData, setEventData] = useState<EventTable>()

    const showModal = () => {
        setIsModalOpen(true);
    };

    const SelectEvent = (event: EventTable) => {
        setEventData(event)
        setIsModalOpen(false)
        Get(event.id)
    }

    const handleChangePage = () => {
        setIsFirstPage(!isFirstPage)
    }

    useEffect(() => {
        if (!loading) {
            setEventData(event)
            if (event?.players) {
                setDataTable(event?.players?.filter((e: EventPlayer) => {
                    return (isFirstPage && (e.hole > 0 && e.hole < 10)) || (!isFirstPage && (e.hole >= 10 && e.hole < 20))
                }))
            }
        }
    }, [event, loading, isFirstPage])

    if (loading) {
        return (<LoadingScreen />)
    }

    const template = tableTemplate.find((t) => t.type === eventData?.type)

    template?.hole.map((item) => {
        const par = eventData?.hole.find((h) => h.hole === item.hole)
        if (par) {
            item.par = par.par
        }

        return item
    })

    return (
        <Layout style={{
            padding: '4rem',
            width: '100%',
            backgroundImage: `url(${eventData?.getBackgroundUrl()})`,
            backgroundSize: 'cover',
            minHeight: '100vh',
            height: '100%',
        }}>
            <Flex
                gap="2rem"
                vertical={isMobile}
            >
                <Flex style={{ width: '100%', minHeight: '70vh' }} vertical>
                    <Flex justify='center' style={{ alignItems: 'center', }}>
                        <Image
                            className='logo-image'
                            onClick={showModal}
                            preview={false}
                            style={{ maxHeight: '8rem', padding: '1rem' }}
                            src={eventData?.getLogoUrl()}
                        />
                    </Flex>
                    {
                        template?.hole.filter((e) => isFirstPage ? e.hole < 5 : e.hole >= 10 && e.hole < 14).map((e) => {
                            return <HoleTable
                                key={e.hole}
                                theme={eventData?.theme}
                                group={template.group}
                                players={dataTable?.filter((d: EventPlayer) => d.hole === e.hole) || []}
                                memberTotal={template.member}
                                hole={e.hole}
                                par={e.par}
                                parList={eventData?.hole || []}
                            />
                        })
                    }
                </Flex>
                <Flex style={{ width: '100%', minHeight: '70vh' }} vertical>
                    {
                        template?.hole.filter((e) => isFirstPage ? e.hole > 4 && e.hole < 10 : e.hole > 13 && e.hole < 19).map((e) => {
                            return <HoleTable
                                key={e.hole}
                                theme={eventData?.theme}
                                group={template.group}
                                players={dataTable?.filter((d: EventPlayer) => d.hole === e.hole) || []}
                                memberTotal={template.member}
                                hole={e.hole}
                                par={e.par}
                                parList={eventData?.hole || []}
                            />
                        })
                    }
                </Flex>
                <Flex vertical={!isMobile} justify={isMobile ? 'end' : 'start'} style={{ flexDirection: isMobile ? 'row-reverse' : 'column' }} gap={'2rem'}>
                    <Flex>
                        <PageButton isMobile={isMobile} backgroundColor={eventData?.theme.mainColor} disabled={!isFirstPage} onClick={handleChangePage}>
                            <CaretRightOutlined style={{ color: !isFirstPage ? 'grey' : event?.theme.holeColor, filter: 'brightness(.75)', fontSize: '1.25rem' }} />
                        </PageButton>
                    </Flex>
                    <Flex>
                        <PageButton isMobile={isMobile} backgroundColor={eventData?.theme.mainColor} disabled={isFirstPage} onClick={handleChangePage}>
                            <CaretLeftOutlined style={{ color: isFirstPage ? 'grey' : event?.theme.holeColor, filter: 'brightness(.75)', fontSize: '1.25rem' }} />
                        </PageButton>
                    </Flex>
                </Flex>
                <Modal title="Select Event" open={isModalOpen} onClose={() => { setIsModalOpen(false) }} onCancel={() => { setIsModalOpen(false) }}>
                    <List>
                        {props.events.map((e: EventTable) => {
                            return (
                                <List.Item key={e.id}>
                                    <Button type='link' onClick={() => {
                                        SelectEvent(e)
                                    }}>
                                        {e.name}
                                    </Button>
                                </List.Item>
                            )
                        })}
                    </List>
                </Modal>
            </Flex>
        </Layout>
    )
}

export default EventScreen